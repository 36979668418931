import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ApiServicesService } from "../../../Services/api-services.service";
import { AlertService } from "../../common/Alert/alert";
@Component({
  selector: "app-sigin",
  templateUrl: "./sigin.component.html",
  styleUrls: ["./sigin.component.scss"],
})
export class SiginComponent implements OnInit {
  UserLoginForm: UntypedFormGroup;
  IsLogin = false;
  options = {
    autoClose: true,
  };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiCall: ApiServicesService,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    let getUserUd = localStorage.getItem("UserId");
    if (getUserUd === null) {
      this.UserLoginForm = this.formBuilder.group({
        email: new UntypedFormControl('', Validators.compose(
          [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')])),
        password: new UntypedFormControl("", Validators.required),
      });
    } else {
      window.location.href = "/dashboard";
    }
  }
  get login() {
    return this.UserLoginForm.controls;
  }
  userLogin() {
    this.IsLogin = true;
    if (this.UserLoginForm.invalid) {
      return;
    }

    let userName = this.UserLoginForm.value.email;
    let password = this.UserLoginForm.value.password;
    this.apiCall.UserLogin(userName, password).subscribe((res) => {
      if (res != null) {
        const UserId = res.id;
        localStorage.setItem("UserId", UserId);
        this.alert.success("Login Success", this.options);
        window.location.href = "/dashboard";
      }
      else if(res==null){
        this.alert.error("Invalid User Name Or Password", this.options);
      }

      else {
        (error) => {
          this.alert.error(error, this.options);
        };
      }
    });
  }
}
