import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { throwError as observableThrowError, Observable, from } from "rxjs";
import { AppConfig } from "../appConfig/app.config";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {}

  public createPhonePeBulkTicket(paymentModel): Observable<any> {
    return this.http.post(
      `${this.appConfig.paymentURL}${this.appConfig.phonePeCreateBulkTicket}`,
      paymentModel
    );
  }
  public createRazoryPayBulkTicket(paymentModel): Observable<any> {
    return this.http.post(
      `${this.appConfig.paymentURL}${this.appConfig.razoryPayCreateBulkTicket}`,
      paymentModel
    );
  }
  public getBulkTicket(): Observable<any> {
    return this.http.get(`${this.appConfig.paymentURL}${this.appConfig.bulkTicketList}`);
  }
}
