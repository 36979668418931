<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a routerLink="/dashboard" class="brand-link">
    <img
      src="../../../../assets/img/azheader.svg"
      alt="AdminLTE Logo"
      class="brand-image img-circle elevation-3"
      style="opacity: 0.8"
    />
    <span class="brand-text font-weight-light">Az Conf</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img
          src="../../../../assets/img/user2-160x160.jpg"
          class="img-circle elevation-2"
          alt="User Image"
        />
      </div>
      <div class="info">
        <a class="d-block">Admin</a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <li class="nav-item" [routerLinkActive]="['menu-open']">
          <a
            routerLink="/dashboard"
            [routerLinkActive]="['active']"
            class="nav-link"
          >
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Dashboard
              <!-- <i class="right fas fa-angle-left"></i> -->
            </p>
          </a>
        </li>

        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fas fa-edit"></i>
            <p>
              Community
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <!-- nav-item menu-is-opening menu-open -->
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/AddCommunities"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Add Community</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="/ListCommunities"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Community List</p>
              </a>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fas fa-users"></i>
            <p>
              Volunteer
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/AddVolunteers"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Add Volunteer</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="/ListVolunteers"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Volunteer List</p>
              </a>
            </li>
          </ul>
        </li>

        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fas fa-dollar-sign"></i>
            <p>
              Sponsor
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/Sponsors"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Add Sponsor</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="/ListSponsors"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Sponsors List</p>
              </a>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fa fa-microphone"></i>
            <p>
              Speaker
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/ListSpeakers"
                [routerLinkActive]="'active'"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Speaker List</p>
              </a>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fas fa-sync"></i>
            <p>
              Sessionize
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/sessionize"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Sessionize</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="/SyncSpeakers"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Sync Sessionize</p>
              </a>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fas fa-money-bill"></i>
            <p>
              Payments
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/pricing"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Price</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="/listprice"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Price List</p>
              </a>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fa fa-gift"></i>
            <p>
              Coupon
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/coupon"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Create Coupon</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="/listcoupon"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Coupon List</p>
              </a>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fa fa-question"></i>
            <p>
              Faq
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/addfaq"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Create Faq</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="/listfaq"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Faq List</p>
              </a>
            </li>
          </ul>
        </li>

        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fa fa-money-bill"></i>
            <p>
              PaymentGateway
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/checkpaymentstatus"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Check payment Status</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="/bulkticket"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Create Bulk Ticket</p>
              </a>
            </li>
            <li class="nav-item">
              <a
                routerLink="/bulkpaymentlist"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>Bulk Payment List</p>
              </a>
            </li>
          </ul>
        </li>

        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fa fa-money-bill"></i>
            <p>
              Attendess
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/attendesslist"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="fas fa-users nav-icon"></i>
                <p>Attendess List</p>
              </a>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          [routerLinkActive]="['menu-is-opening', 'menu-open']"
        >
          <a class="nav-link">
            <i class="nav-icon fa fa-money-bill"></i>
            <p>
              WorkShops
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" [routerLinkActive]="['d-block']">
            <li class="nav-item">
              <a
                routerLink="/workshoplist"
                [routerLinkActive]="['active']"
                class="nav-link"
              >
                <i class="far fa-circle nav-icon"></i>
                <p>WorkShopList</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>
