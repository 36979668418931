import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";

import { ApiServicesService } from "../../../../Services/api-services.service";
import { AlertService } from "../../../common/Alert/alert/alert.services";
import { AppConfig } from "../../../../appConfig/app.config";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-communities-list",
  templateUrl: "./communities-list.component.html",
  styleUrls: ["./communities-list.component.scss"],
})
export class CommunitiesListComponent implements OnInit {
  communtiesList = [];
  // @Output() Data = new EventEmitter<any>();

  pageSizes = [5, 10, 20, 50];
  displayedColumns: string[] = [
    "S.No",
    "Community Name",
    "Contact Person",
    "Mobile Number",
    "Email",
    "Logo",
    "Action",
    "Edit",
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  IsLoading=false
  constructor(
    private apiCall: ApiServicesService,
    private router: Router,
    private alert: AlertService,
    private appConfig: AppConfig
  ) {}

  ngOnInit() {
    this.getCommuntiesAllList();
  }

  getCommuntiesAllList() {
    this.IsLoading=true
    this.apiCall.getCommunities().subscribe((res) => {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
      this.communtiesList = res;
      this.IsLoading=false
    });
  }
  updateCommunties(editId) {
    const editDetails = this.communtiesList.filter((f) => f.id === editId);
    // this.apiCall.communtiesEdit(editDetails)
    this.apiCall.sharedata(editDetails);
    // this.Data.emit(editDetails)
    this.router.navigate(["/editcommunities", editId]);
  }

  changeStatus(event) {
    this.apiCall
      .StatusChangeCommunities(event.id, event.isActive)
      .subscribe((resdata) => {});
  }
  communitiesFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
