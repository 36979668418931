<app-az-header> </app-az-header>
<app-az-menu></app-az-menu>

<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-align-justify"></i> Attendees List
              </div>
              <div class="card-body">
                <div class="col-md-3 search">
                  <input
                    type="text"
                    class="form-control search-box"
                    (keyup)="attendeesFilter($event)"
                    placeholder="Search"
                  />
                </div>

                <table class="table" mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="OrderId">
                    <th *matHeaderCellDef>OrderId</th>
                    <td *matCellDef="let data">
                      {{ data.orderId ? data.orderId : "N/A" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Full Name">
                    <th *matHeaderCellDef>Full Name</th>
                    <td *matCellDef="let data">
                      {{ data.fullName ? data.fullName : "N/A" }}
                      {{ data.lastName }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Email Id">
                    <th *matHeaderCellDef>Email Id</th>
                    <td class="mat-column-email" *matCellDef="let data">
                      {{ data.emailId ? data.emailId : "N/A" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Price Type">
                    <th *matHeaderCellDef>Price Type</th>
                    <td *matCellDef="let data">
                      {{ data.priceType ? data.priceType : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Total Amount">
                    <th *matHeaderCellDef>Total Amount</th>
                    <td *matCellDef="let data">
                      {{
                        data.totalAmount
                          ? (data.totalAmount | currency : "INR")
                          : "N/A"
                      }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="TransAction Id">
                    <th *matHeaderCellDef>Transaction Id</th>
                    <td *matCellDef="let data">
                      {{ data.transactionID ? data.transactionID : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Payment Type">
                    <th *matHeaderCellDef>Payment Type</th>
                    <td *matCellDef="let data">
                      {{ data.paymentType ? data.paymentType : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="State">
                    <th *matHeaderCellDef>State</th>
                    <td *matCellDef="let data">
                      {{ data.paymentState ? data.paymentState : "N/A" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Date">
                    <th *matHeaderCellDef>Date</th>
                    <td *matCellDef="let data">
                      {{
                        data.createdDate
                          ? (data.createdDate | date : "dd-MMM-yyyy")
                          : "N/A"
                      }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Status">
                    <th *matHeaderCellDef>Status</th>
                    <td *matCellDef="let data">
                      <span class="status-success" *ngIf="data.isPayment"
                        >Paid</span
                      >
                      <span class="status-danger" *ngIf="!data.isPayment"
                        >Not Paid</span
                      >
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
                <mat-paginator
                  class="pagnacolor"
                  [pageSizeOptions]="pageSizes"
                  showFirstLastButtons
                ></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
