import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { volunteersAddComponent } from "./views/volunteers/volunteers-add/volunteers-add.component";
import { volunteersListComponent } from "./views/volunteers/volunteers-list/volunteers-list.component";
import { SiginComponent } from "./views/sigin/sigin/sigin.component";
import { CommunitiesAddComponent } from "./views/communities/communities-add/communities-add/communities-add.component";
import { CommunitiesListComponent } from "./views/communities/communities-list/communities-list/communities-list.component";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { VolunteersEditComponent } from "./views/volunteers/volunteers-edit/volunteers-edit.component";
import { SessionizeComponent } from "./views/sessionize/sessionize.component";
import { CommunitiesEditComponent } from "./views/communities/communities-edit/communities-edit.component";
import { AddsponsorsComponent } from "./views/sponsors/addsponsors/addsponsors.component";
import { AzconfdevGuard } from "./azconfdev.guard";
import { ListsponsorsComponent } from "./views/sponsors/listsponsors/listsponsors.component";
import { AzSpeakersListComponent } from "./views/speakers/az-speakers-list/az-speakers-list.component";
import { AzEditSpeakersComponent } from "./views/speakers/az-edit-speakers/az-edit-speakers.component";
import { AzAddSpeakersComponent } from "./views/speakers/az-add-speakers/az-add-speakers.component";
import { AzSyncSpeakersComponent } from "./views/speakers/az-sync-speakers/az-sync-speakers.component";
import { EditsponsorsComponent } from "./views/sponsors/editsponsors/editsponsors.component";
import { WorkshoplistComponent } from "./workshop/workshoplist/workshoplist.component";
import { EditworkshopComponent } from "./workshop/editworkshop/editworkshop.component";
import { PagenotfundComponent } from "./views/notfound/pagenotfund/pagenotfund.component";
import { EventLiveComponent } from "./views/event-live/event-live.component";
import { PricingComponent } from "./views/Payments/pricing/pricing.component";
import { PricinglistComponent } from "./views/Payments/pricing/pricinglist/pricinglist.component";
import { CounponcreateComponent } from "./views/Payments/Coupon/couponcreate/couponcreate.component";
import { CounponlistComponent } from "./views/Payments/Coupon/couponlist/couponlist.component";
import { AddfaqComponent } from "./views/Faq/addfaq/addfaq.component";
import { ListFaqComponent } from "./views/Faq/list-faq/list-faq.component";
import { AttendesslistComponent } from "./views/event/attendesslist/attendesslist.component";
import { CheckpaymentstatusComponent } from "./views/checkpaymentstatus/checkpaymentstatus.component";
import { CreateBulkTicket } from "./views/createbulkticket/createbulkticket.component";
import { BulkTicketList } from "./views/bulkticketlist/bulkticketlist.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "/Sigin",
    pathMatch: "full",
  },
  { path: "Sigin", component: SiginComponent },
  {
    path: "AddVolunteers",
    component: volunteersAddComponent,
    canActivate: [AzconfdevGuard],
  },
  {
    path: "ListVolunteers",
    component: volunteersListComponent,
    canActivate: [AzconfdevGuard],
  },
  {
    path: "AddCommunities",
    component: CommunitiesAddComponent,
    canActivate: [AzconfdevGuard],
  },
  {
    path: "ListCommunities",
    component: CommunitiesListComponent,
    canActivate: [AzconfdevGuard],
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AzconfdevGuard],
  },
  {
    path: "editvolunteer/:userid",
    component: VolunteersEditComponent,
    data: { title: "Edit Volunteers" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "sessionize",
    component: SessionizeComponent,
    canActivate: [AzconfdevGuard],
  },
  {
    path: "editcommunities/:communityid",
    component: CommunitiesEditComponent,
    data: { title: "Edit Communities" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "Sponsors",
    component: AddsponsorsComponent,
    data: { title: "Add Sponser" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "ListSponsors",
    component: ListsponsorsComponent,
    data: { title: "Sponser List" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "ListSpeakers",
    component: AzSpeakersListComponent,
    data: { title: "Sponser List" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "EditSpeakers/:speakerid",
    component: AzEditSpeakersComponent,
    data: { title: "Sponser List" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "AddSpeakers",
    component: AzAddSpeakersComponent,
    data: { title: "Sponser List" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "SyncSpeakers",
    component: AzSyncSpeakersComponent,
    data: { title: "Sponser List" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "sponseredit/:sponserid",
    component: EditsponsorsComponent,
    data: { title: "Sponser Edit" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "workshoplist",
    component: WorkshoplistComponent,
    data: { title: "Workshop List" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "editworkshop/:workshopid",
    component: EditworkshopComponent,
    data: { title: "Workshop Edit" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "eventlive",
    component: EventLiveComponent,
    data: { title: "live" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "pricing",
    component: PricingComponent,
    data: { title: "Price" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "listprice",
    component: PricinglistComponent,
    data: { title: "List Price" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "coupon",
    component: CounponcreateComponent,
    data: { title: "Coupon" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "listcoupon",
    component: CounponlistComponent,
    data: { title: "List Coupon" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "addfaq",
    component: AddfaqComponent,
    data: { title: "Faq" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "listfaq",
    component: ListFaqComponent,
    data: { title: "Faq List" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "attendesslist",
    component: AttendesslistComponent,
    data: { title: "Attendess List" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "checkpaymentstatus",
    component: CheckpaymentstatusComponent,
    data: { title: "Payment Status" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "bulkticket",
    component: CreateBulkTicket,
    data: { title: "Create Bulk Ticket" },
    canActivate: [AzconfdevGuard],
  },
  {
    path: "bulkpaymentlist",
    component: BulkTicketList,
    data: { title: "Bulk Ticket List" },
    canActivate: [AzconfdevGuard],
  },
  { path: "*", component: PagenotfundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
