<app-az-header>
</app-az-header>
<app-az-menu>

</app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>

<div class="content-wrapper">
  <section class="content">

      <div class="container-fluid">
        <div class="animated fadeIn">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-header">
                    <i class="fa fa-align-justify"></i> Communties List
                  </div>
                  <div class="card-body">


                    <div class="col-md-3 search">
                      <input type="text"class="form-control search-box" (keyup)="communitiesFilter($event)"  placeholder="Search">
                      </div>


      <table class="table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="S.No">
          <th *matHeaderCellDef>S.No</th>
          <td  *matCellDef="let data let i = index "> {{paginator.pageIndex * paginator.pageSize+i+1}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Community Name">
          <th   *matHeaderCellDef> Community Name </th>
          <td  *matCellDef="let data  "> {{data.name?data.name:"N/A"}}</td>
        </ng-container>
        <ng-container matColumnDef="Contact Person">
          <th  *matHeaderCellDef> Contact Person </th>
          <td  *matCellDef="let data"> {{data.contactPerson?data.contactPerson:"N/A"}} </td>
        </ng-container>
        <ng-container matColumnDef="Mobile Number">
          <th  *matHeaderCellDef> Mobile Number </th>
          <td  *matCellDef="let data">{{data.mobileNumber?data.mobileNumber:"N/A"}} </td>
        </ng-container>
        <ng-container matColumnDef="Email">
          <th  *matHeaderCellDef> Email </th>
          <td  *matCellDef="let data">{{data.emailId?data.emailId:"N/A"}} </td>
        </ng-container>

        <ng-container matColumnDef="Logo">
          <th  *matHeaderCellDef> Logo </th>
          <td  *matCellDef="let data"><img src="{{data.logoPath?data.logoPath:'assets/img/avatars/6.jpg'}}" class="img-avatar img-class" alt="{{data.name}}"/> </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th  *matHeaderCellDef> Action </th>
          <td  *matCellDef="let data"> <mat-slide-toggle color="primary" [name]="i"  [(ngModel)]="data.isActive"  (change)="changeStatus(data)"  ></mat-slide-toggle> </td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th  *matHeaderCellDef> Edit </th>
          <td  *matCellDef="let data"> <i  class="nav-icon fas fa-edit"  (click)="updateCommunties(data.id)"></i> </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


      </table>
      <mat-paginator class="pagnacolor" [pageSizeOptions]="pageSizes" showFirstLastButtons></mat-paginator>

                  </div>
                </div>
              </div>

            </div>

          </div>

      </div>

</section>
  </div>

