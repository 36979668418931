import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApiServicesService } from "../../Services/api-services.service";

@Component({
  selector: "app-checkpaymentstatus",
  templateUrl: "./checkpaymentstatus.component.html",
  styleUrls: ["./checkpaymentstatus.component.scss"],
})
export class CheckpaymentstatusComponent implements OnInit {
  submitted = false;
  paymentStatusForm: FormGroup;
  returnPaymentStatus: any;
  loader=false
  constructor(
    private formBuilder: FormBuilder,
    private apicall: ApiServicesService
  ) {}

  ngOnInit(): void {
    this.Init();
  }
  Init() {
    this.paymentStatusForm = this.formBuilder.group({
      orderId: new FormControl("", Validators.required),
    });
  }

  get paymentCheckForm() {
    return this.paymentStatusForm.controls;
  }

  CheckStatus() {
    this.submitted = true;
    if (!this.paymentStatusForm.valid) {
      return;
    } else {
      this.loader=true
      this.apicall
        .getPaymentStatus(this.paymentStatusForm.get("orderId").value)
        .subscribe((payment) => {
          this.returnPaymentStatus = payment;
          this.loader=false

        });
        this.loader=false
    }
  }
}
