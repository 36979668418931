import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ApiServicesService } from "../../../../Services/api-services.service";
import { AppConfig } from "../../../../appConfig/app.config";

@Component({
  selector: "app-pricinglist",
  templateUrl: "./pricinglist.component.html",
  styleUrls: ["./pricinglist.component.scss"],
})
export class PricinglistComponent implements OnInit {
  ticketBenifit: any;
  isEdit = false;
  priceDetails: any;
  minDate = new Date();
  priceType: any;
  price: any;
  benefits: any;
  lastDate: any;

  editField: any;
  pageSizes = [5, 10, 20, 50];
  displayedColumns: string[] = [
    "S.No",
    "Price Type",
    "Price",
    "Discount",
    "Benifits",
    "Last Date",
    "Total Ticket",
    "Remaining Ticket",
    "Action",
    "Edit",
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("close") closeModel: ElementRef;
  editForm: UntypedFormGroup;
  submitted = false;
  IsLoading = false;
  constructor(
    private apiCall: ApiServicesService,
    private formBuilder: UntypedFormBuilder,
    private apiConfig: AppConfig
  ) {}

  ngOnInit(): void {
    this.getPrice();
  }

  formInitiate() {
    this.editForm = this.formBuilder.group({
      type: new UntypedFormControl("", Validators.required),
      price: new UntypedFormControl("", Validators.required),
      benefits: new UntypedFormControl("", Validators.required),
      lastDate: new UntypedFormControl("", Validators.required),
      discountPercentage: new UntypedFormControl(""),
      totalTicketCount: new UntypedFormControl("", Validators.required),
      remainingTicketCount: new UntypedFormControl("", Validators.required),
      priceId: new UntypedFormControl(""),
      status: new UntypedFormControl(null),
      isTicketShow: new UntypedFormControl(true),
      eventYear: new UntypedFormControl(this.apiConfig.GetEventYear),
    });
  }

  getPrice() {
    this.IsLoading = true;
    this.apiCall.getPrice("all", true).subscribe((res) => {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
      this.priceDetails = res;
      this.IsLoading = false;
    });
    this.IsLoading = false;
  }

  changeStatus(data) {
    this.apiCall
      .statusChangeprice(data.priceId, data.status)
      .subscribe((pri) => {
        if (pri) {
          //this.getPrice()
        }
      });
  }

  updatePrice(data) {
    this.isEdit = true;
    this.formInitiate();

    this.editForm.patchValue({
      benefits: data.benefits,

      lastDate: data.lastDate,
      price: data.price.toString(),
      discountPercentage: data.discountPercentage,
      priceId: data.priceId,
      type: data.type,
      totalTicketCount:data.totalTicketCount,
      remainingTicketCount:data.remainingTicketCount,
      status: data.status,
    });
  }
  update() {
    this.submitted = true;
    if (this.editForm.invalid) {
      this.isEdit = true;
      return;
    }
    this.IsLoading = true;
    this.editForm
      .get("price")
      .setValue(Number(this.editForm.get("price").value));
    this.editForm
      .get("discountPercentage")
      .setValue(Number(this.editForm.get("discountPercentage").value));
      this.editForm
      .get("totalTicketCount")
      .setValue(Number(this.editForm.get("totalTicketCount").value));
      this.editForm
      .get("remainingTicketCount")
      .setValue(Number(this.editForm.get("remainingTicketCount").value));

    //priceData.benefits=JSON.stringify(priceData.benefits)

    this.apiCall.priceUpdate(this.editForm.value).subscribe((pri) => {
      if (pri) {
        this.isEdit = false;
        this.closeModel.nativeElement.click();
        this.getPrice();
        this.IsLoading = false;
      }
      this.IsLoading = false;
    });
  }
  cancel() {
    this.editField = "";
  }
}
