<app-az-header> </app-az-header>
<app-alert></app-alert>
<app-az-menu></app-az-menu>
<app-loader [Loader]="loader"></app-loader>

<div class="app-body">
  <!-- Main content -->

  <div class="content-wrapper">
    <section class="content">
      <div class="container-fluid">
        <div class="card-body">
          <form
            [formGroup]="paymentStatusForm"
            (ngSubmit)="CheckStatus()"
            class="form-horizontal"
          >
            <div class="form-group col-md-7">
              <label>Merchant Order ID</label>
              <input
                [ngClass]="{
                  'is-invalid': submitted && paymentCheckForm.orderId.errors
                }"
                type="text"
                formControlName="orderId"
                id="count"
                autocomplete="off"
                class="form-control"
                placeholder="Merchant Order ID"
              />

              <div
                *ngIf="submitted && paymentCheckForm.orderId.errors"
                class="invalid-feedback"
              >
                <div *ngIf="paymentCheckForm.orderId.errors.required">
                  Merchant order is required
                </div>
              </div>
            </div>
            <div class="mt-3" style="text-align: center">
              <button class="btn btn-sm btn-primary">
                <i class="fa fa-dot-circle-o"></i> Check
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>

  <div class="row" *ngIf="returnPaymentStatus?.length > 0">
    <div class="col-12 table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Payment Status</th>
            <th>Code</th>
            <th>Message</th>
            <th>Merchant Id</th>
            <th>Merchant TransAction Id</th>
            <th>Transaction Id</th>
            <th>Amount</th>
            <th>State</th>
            <th>Response Code</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment of returnPaymentStatus">
            <td>{{ payment?.success }}</td>
            <td>{{ payment?.code }}</td>
            <td>{{ payment?.message }}</td>
            <td>{{ payment?.data?.merchantId }}</td>
            <td>{{ payment?.data?.merchantTransActionId }}</td>
            <td>{{ payment?.data?.transactionId }}</td>
            <td>{{ payment?.data?.amount }}</td>
            <td>{{ payment?.data?.responseCode }}</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Need for Speed IV</td>
            <td>247-925-726</td>
            <td>Wes Anderson umami biodiesel</td>
            <td>$50.00</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Monsters DVD</td>
            <td>735-845-642</td>
            <td>Terry Richardson helvetica tousled street art master</td>
            <td>$10.70</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Grown Ups Blue Ray</td>
            <td>422-568-642</td>
            <td>Tousled lomo letterpress</td>
            <td>$25.99</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
