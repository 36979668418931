import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import {
  LocationStrategy,

  PathLocationStrategy,
} from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {

  IconSetService,
} from "@coreui/icons-angular";
import { Interceptor } from "./interceptor";
import { AlertModule } from "./views/common/Alert/alert/alert.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app.routing";
import { SiginComponent } from "./views/sigin/sigin/sigin.component";
import { volunteersAddComponent } from "./views/volunteers/volunteers-add/volunteers-add.component";
import { volunteersListComponent } from "./views/volunteers/volunteers-list/volunteers-list.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppConfig } from "./appConfig/app.config";
import { AzFooterComponent } from "./views/common/az-footer/az-footer.component";
import { AzMenuComponent } from "./views/common/az-menu/az-menu.component";
import { AzHeaderComponent } from "./views/common/az-header/az-header.component";
import { CommunitiesAddComponent } from "./views/communities/communities-add/communities-add/communities-add.component";
import { CommunitiesListComponent } from "./views/communities/communities-list/communities-list/communities-list.component";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { VolunteersEditComponent } from "./views/volunteers/volunteers-edit/volunteers-edit.component";
import { SessionizeComponent } from "./views/sessionize/sessionize.component";
import { ListsponsorsComponent } from "./views/sponsors/listsponsors/listsponsors.component";
import { AddpartnersComponent } from "./views/partners/addpartners/addpartners.component";
import { CommunitiesEditComponent } from "./views/communities/communities-edit/communities-edit.component";
import { AddsponsorsComponent } from "./views/sponsors/addsponsors/addsponsors.component";
import { AuthuguradServiceService } from "./authugurad-service.service";
import { AzSpeakersListComponent } from "./views/speakers/az-speakers-list/az-speakers-list.component";
import { AzEditSpeakersComponent } from "./views/speakers/az-edit-speakers/az-edit-speakers.component";
import { AzAddSpeakersComponent } from "./views/speakers/az-add-speakers/az-add-speakers.component";
import { AzSyncSpeakersComponent } from "./views/speakers/az-sync-speakers/az-sync-speakers.component";

import { EditsponsorsComponent } from "./views/sponsors/editsponsors/editsponsors.component";
import { WorkshoplistComponent } from "./workshop/workshoplist/workshoplist.component";
import { EditworkshopComponent } from "./workshop/editworkshop/editworkshop.component";
import { PagenotfundComponent } from "./views/notfound/pagenotfund/pagenotfund.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { EventLiveComponent } from "./views/event-live/event-live.component";
import { PricingComponent } from "./views/Payments/pricing/pricing.component";
import { PricinglistComponent } from "./views/Payments/pricing/pricinglist/pricinglist.component";
import { NumbericOnlyDirective } from "./directive/numberic-only.directive";

import { CounponlistComponent } from "./views/Payments/Coupon/couponlist/couponlist.component";
import { CounponcreateComponent } from "./views/Payments/Coupon/couponcreate/couponcreate.component";
import { ListpartnersComponent } from "./views/partners/listpartners/listpartners.component";
import { AddfaqComponent } from "./views/Faq/addfaq/addfaq.component";
import { ListFaqComponent } from "./views/Faq/list-faq/list-faq.component";
import { AttendesslistComponent } from "./views/event/attendesslist/attendesslist.component";
import { LoaderComponent } from "./views/common/Loader/loader/loader.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { CheckpaymentstatusComponent } from "./views/checkpaymentstatus/checkpaymentstatus.component";
import { CreateBulkTicket } from "./views/createbulkticket/createbulkticket.component";
import { BulkTicketList } from "./views/bulkticketlist/bulkticketlist.component";
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    AlertModule,
    CKEditorModule,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatTableModule,
  ],
  declarations: [
    AppComponent,
    SiginComponent,
    volunteersAddComponent,
    volunteersListComponent,
    AzFooterComponent,
    AzMenuComponent,
    AzHeaderComponent,
    CommunitiesAddComponent,
    CommunitiesListComponent,
    DashboardComponent,
    VolunteersEditComponent,
    SessionizeComponent,
    ListpartnersComponent,
    ListsponsorsComponent,
    AddpartnersComponent,
    CommunitiesEditComponent,
    AddsponsorsComponent,
    AzSpeakersListComponent,
    AzEditSpeakersComponent,
    AzAddSpeakersComponent,
    NumbericOnlyDirective,
    AzSyncSpeakersComponent,
    EditsponsorsComponent,
    WorkshoplistComponent,
    EditworkshopComponent,
    PagenotfundComponent,
    EventLiveComponent,
    PricingComponent,
    PricinglistComponent,
    CounponcreateComponent,
    CounponlistComponent,
    AddfaqComponent,
    ListFaqComponent,
    AttendesslistComponent,
    LoaderComponent,
    CheckpaymentstatusComponent,
    CreateBulkTicket,
    BulkTicketList
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      /*API interceptor should invoked here to attach with all http request */
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (apiCall: ApiServicesService) => () => apiCall.load(),
    //   deps: [ApiServicesService],
    //   multi: true,
    // },
    IconSetService,
    AppConfig,
    AuthuguradServiceService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
